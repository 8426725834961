<template>
  <div class="container">
    <div class="timeline-block timeline-block-right" v-motion-slide-top :delay="300">
        <div class="marker"></div>
        <div class="timeline-content">
          <h3 class="text-white">Software Developer</h3>
          <span class="text-white">Phorus Group, Jan 2023 - Sep 2024</span>
          <p class="text-white">
            I actively participate in the migration of an Internal Developer Platform (IDP) using Backstage.io, enhancing project, group, and user management. I develop custom plugins tailored to project-specific needs and contribute to continuous improvement of key functionalities to ensure a seamless user experience. Additionally, I provide technical support, addressing platform-related issues, doubts, and inquiries.
          </p>
        </div>
    </div>

    <div class="timeline-block timeline-block-left" v-motion-slide-top :delay="450">
      <div class="marker"></div>
      <div class="timeline-content">
        <h3>Frontend Developer</h3>
        <span>Valko-UI, Aug 2023 - Dec 2023 </span>
        <p>
          As a contributor to the Valko-UI Open Source project, a component library, I utilize Nuxt.js, Vue 3, and Tailwind CSS to collaborate on the design and development of a wide range of components. My involvement in this project involves facilitating developers in creating attractive and functional user interfaces. Additionally, I actively participate in design discussions and technical decision-making within the development team 
        </p>
      </div>
    </div>

    <div class="timeline-block  timeline-block-left" v-motion-slide-top :delay="650">
        <div class="marker "></div>
        <div class="timeline-content">
          <h3>Software Developer</h3>
          <span>Freelancer, March 2022 - Aug 2022</span>
          <p>
            I developed a local application to manage the inventory of books for a library, using JavaScript, Vue 2, and SQLite in the initial version. Subsequently, I migrated the application to Vue 3 and added multi-platform access functionality through Firebase, thus meeting the client's needs and expanding its reach and utility
          </p>
        </div>
    </div>
  </div>
</template>
<style scoped>
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background: #F1F2F6;
}

h1 {
  text-align: center;
  font-weight: 300;
  color: #777
}

h1 span {
  font-weight: 600;
}

.container {
  width: 80%;
  position: relative;
  overflow: hidden;
}

.container:before {
  content: '';
  position: absolute;
  top: 10;
  left: 50%;
  margin-left: -1px;
  width: 2px;
  height: 100%;
  background: #CCD1D9;
  z-index: 1
}

.timeline-block {
  width: -webkit-calc(50% + 8px);
  width: -moz-calc(50% + 8px);
  width: calc(50% + 8px);
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  justify-content: space-between;
  clear: both;
}

.timeline-block-right {
  float: right;
}

.timeline-block-left {
  float: left;
  direction: rtl
}

.marker {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid #F5F7FA;
  background: #e9d54f;
  margin-top: 10px;
  z-index: 9999
}

.timeline-content {
  width: 95%;
  padding: 0 15px;
  color: #666
}

.timeline-content h3 {
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 25px;
  font-weight: 500
}

.timeline-content span {
  font-size: 15px;
  color: #a4a4a4;
}

.timeline-content p {
  font-size: 14px;
  line-height: 1.5em;
  word-spacing: 1px;
  color: #888;
}

@media screen and (max-width: 320px) {
  .container {
    width: 20%;
  }
  .timeline-content {
    width: 100%;
    padding: 0 5px;
  }

}

@media screen and (max-width: 375px) {
  .container {
    width: 100%;
  }
  .timeline-content {
  width: 100%;
  padding: 0 1px;
  }
}


@media screen and (min-width: 1821px) and (max-width: 2560px) and (min-resolution: 192dpi) {
  .timeline-content h3 {
  margin-top: 1px;
  margin-bottom: 5px;
  font-size: 25px;
  font-weight: 500
}

.timeline-content span {
  font-size: 20px;
  color: #a4a4a4;
}

.timeline-content p {
  font-size: 20px;
  line-height: 1.5em;
  word-spacing: 1px;
  color: #888;
}
}

@media screen and (max-width: 1300px) {
  .timeline-content h3 {
  margin-top: 1px;
  margin-bottom: 5px;
  font-size: 25px;
  font-weight: 500
}

.timeline-content span {
  font-size: 11px;
  color: #a4a4a4;
}

.timeline-content p {
  font-size: 10px;
  line-height: 1.5em;
  word-spacing: 1px;
  color: #888;
}
}

@media screen and (max-width: 1400px) {
  .timeline-content h3 {
  margin-top: 1px;
  margin-bottom: 5px;
  font-size: 25px;
  font-weight: 500
}

.timeline-content span {
  font-size: 12px;
  color: #a4a4a4;
}

.timeline-content p {
  font-size: 11px;
  line-height: 1.5em;
  word-spacing: 1px;
  color: #888;
}
}


@media screen and (max-width: 768px) {
  .container:before {
    left: 20px;
    width: 2px;
  }
  .timeline-block {
    width: 100%;
    margin-bottom: 20px;
  }
  .timeline-block-right {
    float: none;
  }

  .timeline-block-left {
    float: none;
    direction: ltr;
  }
}

</style>
